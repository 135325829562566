import { inject } from 'vue';

import type { UserContextApi } from '../App.types';

export const UserContext = Symbol('UserContext');

export function useUserContext(componentName: string) {
    const context = inject<UserContextApi>(UserContext);

    if (!context) {
        throw new Error(`<${componentName} /> is missing a parent <PrecisionAppContainer /> component.`);
    }

    return context;
}
