<template>
    <SidebarGroup name="Practice Analysis">
        <template #activator>Practice Dashboards</template>

        <template #icon-left>
            <VIcon decorative name="dashboard-thick" />
        </template>

        <SidebarItem name="Practice Dashboard" sub-item :to="{ name: 'team.dashboard' }">
            Practice Dashboard
        </SidebarItem>

        <SidebarItem name="Deadlines Dashboard" sub-item :to="{ name: 'team.deadlines' }" v-if="deadlinesEnabled">
            Deadlines Dashboard
        </SidebarItem>

        <SidebarItem
            v-if="isPracticeInsightsDashboardEnabled"
            name="Insights Dashboard"
            sub-item
            :to="{ name: 'team.practiceInsightsDashboard' }"
        >
            Insights Dashboard
        </SidebarItem>

        <SidebarItem name="Activity Stats" sub-item :to="{ name: 'team.activityStats' }"> Activity Stats </SidebarItem>

        <SidebarItem v-if="linkedToHMRC" name="MTD Dashboard" sub-item :to="{ name: 'team.mtdDashboard' }">
            MTD Dashboard
        </SidebarItem>

        <SidebarItem name="Focus Dashboard" sub-item :to="{ name: 'team.focus.dashboard' }">
            Workflows Dashboard
        </SidebarItem>

        <SidebarItem name="Custom Reports" sub-item :to="{ name: 'team.reports' }"> Custom Dashboards </SidebarItem>
    </SidebarGroup>
</template>

<script>
import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';
import { Feature } from '@/enums/Feature';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

export default {
    name: 'TeamSidebarGroupPracticeAnalysis',

    components: { SidebarGroup, SidebarItem },

    setup() {
        const { linkedToHMRC, teamLocation } = useXavierGlobals();
        const deadlinesEnabled = useIsFeatureEnabled(Feature.DEADLINES_DASHBOARD) && teamLocation === 'GB';
        const isPracticeInsightsDashboardEnabled = useIsFeatureEnabled(Feature.PRACTICE_INSIGHTS_DASHBOARD);

        return {
            linkedToHMRC,
            deadlinesEnabled,
            isPracticeInsightsDashboardEnabled,
        };
    },
};
</script>

<style lang="scss" scoped></style>
