import { defineComponent, provide, watch, inject, ref } from 'vue';

import { useRoute } from '@/composables/useRoute';
import AnalyticsService from '@/services/AnalyticsService';

import type { TrackingContext } from '@/services/AnalyticsService';
import type { Ref } from 'vue';

export interface Api {
    reset(): void;
    setTrackingContext(trackingContext: TrackingContext): void;
    trackButtonClick(name: string, options?: Record<string, string | number>, trackingContext?: TrackingContext): void;
    trackElementClick(
        name: string,
        type: string,
        options?: Record<string, string | number>,
        trackingContext?: TrackingContext
    ): void;
    trackLinkClick(name: string, url: string, isExternal: boolean, trackingContext?: TrackingContext): void;
    trackFormFieldValueChange(
        name: string,
        params?: {
            type?: string;
            value?: string;
        },
        trackingContext?: TrackingContext
    ): void;
    trackingContext: Ref<TrackingContext>;
}

const analytics = new AnalyticsService();

export const AnalyticsContext = Symbol('AnalyticsContext');

export function useAnalytics() {
    const analyticsContext = inject<Api>(AnalyticsContext);

    return analyticsContext;
}

export default defineComponent({
    setup(_props, context) {
        const route = useRoute();

        const curentTrackingContext = ref<TrackingContext>({
            page: route?.value?.name,
            surface: { type: 'Page' },
            url: window.location.pathname,
        });

        const api: Api = {
            /**
             * Current trackingContext value.
             */
            trackingContext: curentTrackingContext,

            /**
             * Reset the tracking context to the default value. This also gets
             * called on evry route change since we need to keep the route
             * information up to date.
             */
            reset() {
                curentTrackingContext.value = {
                    page: route?.name,
                    surface: { type: 'Page' },
                    url: window.location.pathname,
                };
            },

            /**
             * Set the tracking context to something custom before any events
             * are called.
             *
             * @param context
             */
            setTrackingContext(trackingContext = {}) {
                curentTrackingContext.value = { ...curentTrackingContext.value, ...trackingContext };
            },

            /**
             * Track a button click event.
             *
             * @param name Button name
             * @param options Any additional parameters to pass to Segment.
             * @param trackingContext
             */
            trackButtonClick(name, options = {}, trackingContext = {}) {
                analytics.trackButtonClick(name, { ...curentTrackingContext.value, ...trackingContext }, options);
            },

            /**
             * Track an element click that doesn't fit into either of the
             * "Button" or "Link" categories.
             *
             * @param name Element name
             * @param type Element type
             * @param options Any additional parameters to pass to Segment.
             * @param trackingContext
             */
            trackElementClick(name, type, options = {}, trackingContext = {}) {
                analytics.trackElementClick({
                    name,
                    type,
                    trackingContext: { ...curentTrackingContext.value, ...trackingContext },
                    options,
                });
            },

            /**
             * Track a form field and it's value change event. This is not magic
             * and won't actually listen for a form field value change, this
             * method must be called from an `onChange` event if the value is
             * to be tracked.
             *
             * @param name For field name
             * @param params Any data we wish to track about the form field
             * @param trackingContext
             */
            trackFormFieldValueChange(name, params = {}, trackingContext = {}) {
                const defaults = {
                    type: 'text',
                    value: '',
                };

                analytics.trackFormFieldValueChange(
                    name,
                    { ...defaults, ...params },
                    { ...curentTrackingContext.value, ...trackingContext }
                );
            },

            trackLinkClick(name, url, isExternal = false, trackingContext = {}) {
                analytics.trackLinkClick(name, url, isExternal, {
                    ...curentTrackingContext.value,
                    ...trackingContext,
                });
            },
        };

        provide(AnalyticsContext, api);
        // Fallback for older components
        provide('analyticsProvider', api);
        watch(() => route, api.reset);

        return () => context.slots.default?.();
    },
});
