/**
 * Get the keys of an object whilst preserving the types. Javascript's native
 * `Object.keys` function returns `string[]` instead of `Array<keyof Obj>`.
 *
 * @param obj
 */
export function objectKeys<Obj extends object>(obj: Obj) {
    return Object.keys(obj) as Array<keyof Obj>;
}

/**
 * Get the values of an object whilst preserving the types. Javascript's native
 * `Object.values` function returns `unknown[]` instead of
 * `Array<Obj[keyof Obj]>`.
 *
 * @param obj
 */
export function objectValues<Obj extends object>(obj: Obj) {
    return Object.values(obj) as Array<Obj[keyof Obj]>;
}

/**
 * Like Lodash's `omit` function, but types are preserved.
 *
 * @param obj
 * @param keys
 * @returns
 */
export function omit<Obj extends Record<string, unknown>, Keys extends (keyof Obj)[] = (keyof Obj)[]>(
    obj: Obj,
    keys: Keys
): Omit<Obj, Keys[number]> {
    const cloned = { ...obj };

    keys.forEach((key) => delete cloned[key]);

    return cloned;
}

/**
 * Sort an object by its keys.
 *
 * @param obj
 * @returns
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function sortObject<Obj extends Record<any, any>>(obj: Obj) {
    return Object.keys(obj)
        .sort()
        .reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {} as Obj);
}
