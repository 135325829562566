<template>
    <div class="page-layout" data-qa="page-layout-default">
        <header v-if="!isEmbedded" class="page-header">
            <SystemBarsHeader :current-team="currentTeam" />
            <TopNav />
        </header>

        <div v-if="!isEmbedded" class="page-sidebar">
            <Component :is="sidebarComponent" :key="sidebarComponent?.name" />
        </div>

        <main class="page-content">
            <RouterView v-if="dataReady" :key="$route.path" />
        </main>

        <footer class="page-footer">
            <SystemBarsFooter />
        </footer>

        <SessionTimeoutModal
            v-model="showSessionEndModal"
            :timeout-threshold-seconds="sessionEndThresholdMs / 1000"
            @visibility-change="(visible) => !visible && ping()"
        />

        <VideoModal v-model="videoModalVisible" />

        <NotificationManager />

        <PortalTarget multiple name="modals_LEGACY" />
        <PortalTarget multiple name="menus" />
        <div id="modals-target" />
        <div id="menus-target" />
    </div>
</template>

<script>
import { findLast } from 'lodash-es';
import { reactive, toRefs } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { useAppContext } from '@/App/composables/useAppContext';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import VideoModal from '@/layout/PageLayout/VideoModal.vue';
import ClientVisibilityForm from '@/scenes/ClientVisibility/ClientVisibilityForm.vue';
import AnalyticsService from '@/services/AnalyticsService';
import KeepAliveService from '@/services/Api/KeepAliveService';
import UserVoiceService from '@/services/UserVoice';
import useCurrentTeam from '@/store/hooks/useCurrentTeam';

import NotificationManager from './NotificationManager.vue';
import SessionTimeoutModal from './SessionTimeoutModal.vue';
import { SystemBarsFooter, SystemBarsHeader } from './SystemBars';

import TopNav from '../TopNav';

const analytics = new AnalyticsService();
const userVoice = new UserVoiceService();

const apiEndpoints = {
    ping: () => KeepAliveService.ping(),
};

export default {
    name: 'PageLayout',

    components: {
        VideoModal,
        ClientVisibilityForm,
        NotificationManager,
        SessionTimeoutModal,
        SystemBarsFooter,
        SystemBarsHeader,
        TopNav,
    },

    inject: ['analyticsProvider'],

    setup() {
        const xavier = useXavierGlobals();
        const currentTeam = useCurrentTeam();
        const state = reactive({ videoModalVisible: false });
        const { isEmbedded } = useAppContext();

        return {
            ...toRefs(state),

            clientCount: currentTeam.value.clientCount ?? 0,
            clientLimit: xavier.currentTeam?.clientLimit ?? 0,
            clientLimitReached: currentTeam.value.clientLimitReached ?? false,
            currentTeam,
            isEmbedded,
            isManualBilling: currentTeam.value.isManualBilling,
            subscriptionStatus: currentTeam.value.subscriptionStatus,
        };
    },

    data() {
        return {
            hasSpecialBanner: false,
            sessionEndThresholdMs: 0,
            showSessionEndModal: false,
            sidebarMargin: 0,
            sidebarPadding: 0,
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'users/currentUser',
            isDextAccountSwitcherEnabled: 'teams/isDextAccountSwitcherEnabled',
        }),

        dataReady() {
            return !!this.currentTeam;
        },

        sidebarComponent() {
            const match = findLast(this.$route.matched, 'meta.sidebar');

            return match ? match.meta.sidebar : null;
        },

        sidebarStacked() {
            const match = findLast(this.$route.matched, 'meta.sidebarStacked');

            return match ? match.meta.sidebarStacked : false;
        },
    },

    watch: {
        currentUser(user) {
            if (!user) return;

            this.setupUserVoiceWidget();
        },
    },

    methods: {
        ...apiEndpoints,
        ...mapActions({
            loadTeamList: 'teams/loadTeamList',
            loadUserList: 'users/loadUserList',
            loadMembershipList: 'teams/loadMembershipListList',
        }),

        setupUserVoiceWidget() {
            userVoice.setForumIds(this.$xavier.userVoiceWidget.forumIds);
            userVoice.setJwt(this.$xavier.userVoiceWidget.jwt);

            userVoice.setup();

            userVoice.setUserDetails({
                created_at: this.currentUser.createdAt,
                email: this.currentUser.email,
                id: this.currentUser.id,
                name: this.currentUser.name,
            });
        },

        addRootListeners() {
            this.$root.$on('show-video', () => {
                this.videoModalVisible = true;
            });
        },
    },

    created() {
        const teamProducts = this.currentTeam.teamProducts;

        this.$bus.$on('sessionEnding', (data) => {
            this.sessionEndThresholdMs = data?.endThreshold ?? 0;

            this.$nextTick(() => {
                this.showSessionEndModal = true;
            });
        });

        this.$bus.$on('sessionEnded', () => {
            this.sessionEndThresholdMs = 0;
            analytics.resetUserDetails();

            this.$nextTick(() => {
                this.showSessionEndModal = true;
            });
        });

        analytics.setUserDetails(this.$xavier.segmentUserData['id'], this.$xavier.segmentUserData);

        const loadTeamsAndMemberships = async () => {
            await this.loadTeamList();
            if (this.isDextAccountSwitcherEnabled) {
                this.loadMembershipList();
            }
        };

        loadTeamsAndMemberships();
        this.loadUserList();
    },

    mounted() {
        this.hasSpecialBanner = Boolean(document.querySelector('.special-banner'));

        this.addRootListeners();
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.page-layout {
    display: grid;
    grid-template-areas:
        'header header'
        'sidebar content'
        'footer footer';
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: auto 1fr;
    height: 100%;
}

.page-header {
    grid-area: header;
}

.page-sidebar {
    grid-area: sidebar;
    width: 216px;
}

.page-content {
    grid-area: content;
    overflow: hidden;
}

.page-footer {
    grid-area: footer;
}

.switch-link {
    // dext-backwards-compatibility important-rule
    color: get-color(orange) !important;
    padding: 10px !important;
    right: 0;
    top: 7px;
    transition: color 0.2s ease-in-out !important;

    &:hover {
        color: get-color(blue) !important;
    }
}

.sidebar-minimised .switch-link {
    // dext-backwards-compatibility important-rule
    padding: 10px 5px !important;
}
</style>
