import { Feature } from '@/enums/Feature';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const hasDexterityScenesEnabled = useIsFeatureEnabled(Feature.DEXTERITY_SCENES);

const focusRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/Focus/FocusScene.vue'),
        name: 'team.focus',
        path: 'focus',
    },
    {
        component: () => import('@/scenes/Focus/CustomChecks/CustomChecksScene.vue'),
        name: 'team.focus.customChecks',
        path: 'focus/custom-checks',
    },
    {
        component: () => import('@/scenes/WorkflowsDashboard/WorkflowsDashboardSceneContainer.vue'),
        name: 'team.focus.dashboard',
        path: 'focus/dashboard',
    },
    {
        component: () => import('@/scenes/Focus/FlowCreator/FlowCreatorScene.vue'),
        name: 'team.focus.flowCreator',
        path: 'focus/flow-creator',
        props: extractClientIdParam,
    },
    {
        component: () => import('@/scenes/Focus/FlowTemplates/FlowTemplatesScene.vue'),
        name: 'team.focus.flowTemplates',
        path: 'focus/flow-templates',
    },
    {
        component: () => import('@/scenes/Focus/RepeatingFlows/RepeatingFlowsScene.vue'),
        name: 'team.focus.repeatingFlows',
        path: 'focus/repeating-flows',
        redirect: hasDexterityScenesEnabled ? { name: 'team.workflows.repeating.list' } : undefined,
    },
];

export { focusRoutes };
