import { extractClientIdParam } from '../utils';
import type { RouteConfig } from 'vue-router';

const cleanupRoutes: RouteConfig[] = [
    {
        path: 'cleanup/bank-reconciliation',
        component: () => import('@/scenes/BankReconciliation/BankReconciliationScene.vue'),
        props: extractClientIdParam,
        name: 'client.cleanup.bankReconciliation',
    },
    {
        path: 'cleanup/unreconciled-transactions',
        component: () => import('@/scenes/UnreconciledTransactions/UnreconciledTransactionsScene.vue'),
        props: extractClientIdParam,
        name: 'client.cleanup.unreconciledTransactions',
    },
    {
        path: 'cleanup/duplicate-contacts',
        component: () => import('@/scenes/DuplicateContacts/DuplicateContactsScene.vue'),
        props: extractClientIdParam,
        name: 'client.cleanup.duplicateContacts',
    },
    {
        path: 'cleanup/multi-coded-contacts',
        component: () => import('@/scenes/MultiCodedContacts/MultiCodedContactsScene.vue'),
        props: extractClientIdParam,
        name: 'client.cleanup.multi-codedContacts',
    },
    {
        path: 'cleanup/contact-defaults',
        component: () => import('@/scenes/ContactDefaults/ContactDefaultsScene.vue'),
        props: extractClientIdParam,
        name: 'client.cleanup.contactDefaults',
    },
    {
        path: 'cleanup/dormant-accounts',
        component: () => import('@/scenes/DormantAccounts/DormantAccountsScene.vue'),
        props: extractClientIdParam,
        name: 'client.cleanup.dormantAccounts',
    },
    {
        path: 'cleanup/duplicate-transactions',
        component: () => import('@/scenes/DuplicateTransactions/DuplicateTransactionsScene.vue'),
        props: extractClientIdParam,
        name: 'client.cleanup.duplicateTransactions',
    },
    {
        path: 'cleanup/transaction-cleanup',
        component: () => import('@/scenes/TransactionCleanup/TransactionCleanupScene.vue'),
        props: extractClientIdParam,
        name: 'client.cleanup.transactionCleanup',
    },
    {
        path: 'cleanup/abn-validation',
        component: () => import('@/scenes/AbnValidation/AbnValidationScene.vue'),
        props: extractClientIdParam,
        name: 'client.cleanup.abnValidation',
    },
];

export { cleanupRoutes };
