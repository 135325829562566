import { defineStore, acceptHMRUpdate, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import { RequestIds } from '@/enums/RequestIds';

import { useRequestsStore } from '../requests/requests.store';
import { useTeamsStore } from '../teams/teams.store';

const useAppStore = defineStore('app.store', () => {
    const anyRequestsInProgress = ref(false);
    const anyRequestsFailed = ref(false);

    const requestStore = useRequestsStore();
    const teamsStore = useTeamsStore();

    const { isFailure, isInProgress } = storeToRefs(requestStore);

    const isInitialised = computed(() => {
        const hasTeams = teamsStore.allIds.length > 0;

        return anyRequestsInProgress.value === false && anyRequestsFailed.value === false && hasTeams;
    });

    // Watch for any request to fail.
    watch([() => isFailure.value(RequestIds.TEAMS_LOAD)], (newValues) => {
        anyRequestsFailed.value = newValues.some((value) => value === true);
    });

    // Watch for any request to be in progress.
    watch([() => isInProgress.value(RequestIds.TEAMS_LOAD)], (newValues) => {
        anyRequestsInProgress.value = newValues.some((value) => value === true);
    });

    async function init() {
        const promises = [teamsStore.fetchTeamsList()];

        await Promise.all(promises);
    }

    return {
        hasErrors: anyRequestsFailed,
        isLoading: anyRequestsInProgress,
        isInitialised,
        init,
    };
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}

export { useAppStore };
