<template>
    <PoseTransition>
        <FadeDiv v-if="userHasAccess" ref="appSwitcher" class="app-switcher-container">
            <button
                ref="toggleButton"
                class="app-switcher-button"
                :class="{ 'app-switcher-button-active': isVisible }"
                :disabled="isDisabled"
                :title="isDisabled ? 'Loading application switcher' : 'Toggle application switcher'"
                @click.prevent="toggleVisibility"
            >
                <VIcon class="app-switcher-icon" decorative name="apps-filled" size="16" :vertical-align="false" />
            </button>

            <PoseTransition>
                <FadeDiv v-if="isVisible" class="app-switcher" :class="{ 'app-switcher-visible': isVisible }">
                    <h4 class="app-switcher-title">Switch to</h4>

                    <nav ref="nav" class="app-switcher-nav">
                        <template v-for="(navItem, index) in items">
                            <a
                                :key="navItem.id"
                                ref="links"
                                class="app-switcher-link"
                                :href="navItem.href"
                                @focus="currentFocusIndex = index"
                            >
                                <div class="app-switcher-link-content">
                                    <VIcon
                                        v-if="navItem.icon"
                                        class="app-switcher-link-icon"
                                        :class="`app-switcher-link-icon-${navItem.id}`"
                                        :name="navItem.icon"
                                        size="18"
                                    />
                                    <div class="app-switcher-label">
                                        <div class="app-switcher-product-name">
                                            {{ navItem.label }}
                                        </div>
                                        <div v-if="navItem.tagline" class="app-switcher-tagline">
                                            {{ navItem.tagline }}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </template>
                    </nav>
                </FadeDiv>
            </PoseTransition>
        </FadeDiv>
    </PoseTransition>
</template>

<script>
import posed, { PoseTransition } from 'vue-pose';
import { mapActions, mapGetters } from 'vuex';

import { Feature } from '@/enums/Feature';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

export default {
    name: 'AppSwitcher',
    components: {
        PoseTransition,
        FadeDiv: posed.div({
            enter: { opacity: 1, transition: { duration: 200, ease: 'easeInOut' } },
            exit: { opacity: 0, transition: { duration: 200, ease: 'easeInOut' } },
        }),
    },

    data() {
        return {
            currentFocusIndex: -1,
            isDisabled: false,
            isVisible: false,
            navItems: null,
            userHasAccess: false,
        };
    },

    computed: {
        ...mapGetters({
            currentDextUser: 'users/currentDextUser',
            isLoading: 'users/loading',
        }),

        /**
         * Compute the app switcher items. If the current application user does
         * not have a valid subscription to both Dext applications, they do not
         * get to see the Dext Homepage link.
         *
         * This is determined by whether or not the team subscription status is
         * active. If they are in the trial period, they do not get to see the
         * Home link.
         *
         * @returns {Array} Navigation items
         */
        items() {
            const validProductStatusList = ['active', 'trial'];
            const { teamProducts, teamIsDext, userIsDext } = window.Xavier;
            const systems = this.currentDextUser?.systems ?? [];
            const teamHasPrecision = teamProducts.precision && validProductStatusList.includes(teamProducts.precision);
            const teamHasPrepare = teamProducts.prepare && validProductStatusList.includes(teamProducts.prepare);
            const userHasPrepare = systems.length && systems.includes('receiptbank');
            const isValidDext = teamIsDext && userIsDext;

            /**
             * Given I am a dext user
             * and I am in a dext team
             * (these conditions are handled in this.userHasAccess())
             * and my organisation has access to both precision and prepare
             * and I have access to prepare (receiptbank)
             */
            const showHomeLink = teamHasPrecision && teamHasPrepare && userHasPrepare;
            const isLinkToCentralClientListVisible =
                useIsFeatureEnabled(Feature.CENTRAL_CLIENT_LIST) && isValidDext && userHasPrepare && teamHasPrepare;

            return [
                !useIsFeatureEnabled(Feature.REMOVE_APP_SWITCHER_CLIENT_LIST_HOMEPAGE) && {
                    // icon: 'home-thick',
                    id: 'home',
                    isVisible: showHomeLink,
                    label: 'Dext Homepage',
                    href: window.Xavier.dextHomePageUrl,
                },
                !useIsFeatureEnabled(Feature.REMOVE_APP_SWITCHER_CLIENT_LIST_HOMEPAGE) && {
                    id: 'central-client-list',
                    isVisible: isLinkToCentralClientListVisible,
                    label: 'Dext Client List',
                    tagline: '',
                    href: `${window.Xavier.urls.dextCentralClientList}`,
                },
                {
                    // icon: 'request-paperwork-thick',
                    id: 'prepare',
                    isVisible: isValidDext && teamHasPrepare && userHasPrepare,
                    label: 'Dext Prepare',
                    tagline: 'with Receipt Bank',
                    href: `${window.Xavier.dextBaseUrl}/organisations/${window.Xavier.currentTeam.rbExternalId}/prepare/access`,
                },
                {
                    // icon: 'rocket-thick',
                    id: 'precision',
                    isVisible: true,
                    label: 'Dext Precision',
                    tagline: 'with Xavier',
                    href: `${window.Xavier.dextBaseUrl}/organisations/${window.Xavier.currentTeam.rbExternalId}/precision/access`,
                },
            ].filter((item) => item.isVisible);
        },
    },

    watch: {
        isLoading(value) {
            this.isDisabled = value;
        },
    },

    methods: {
        ...mapActions({
            loadDextTeamMembers: 'users/loadDextUserList',
        }),
        /**
         * Reset dropdown visibility and focus activator button.
         *
         * @returns {void}
         */
        close() {
            this.currentFocusIndex = -1;
            this.isVisible = false;
            this.$refs.toggleButton.focus();
        },
        /**
         * Focus the next element in the navigation.
         * Will not loop back to start.
         *
         * @returns {void}
         */
        focusNext() {
            const next = this.currentFocusIndex + 1;
            const { links } = this.$refs;

            if (this.isVisible && next <= links.length - 1) {
                this.$refs.links[next].focus();
            }
        },

        /**
         * Focus the previous element in the navigation.
         * Will not loop back to end.
         *
         * @returns {void}
         */
        focusPrevious() {
            const previous = this.currentFocusIndex - 1;

            if (this.isVisible && previous >= 0) {
                this.$refs.links[previous].focus();
            }
        },

        /**
         * Handle tab press.
         *
         * @param {KeyboardEvent}
         * @returns {void}
         */
        handleTabPress(event) {
            const { shiftKey } = event;
            const links = this.$refs.links;
            const tabBackward = this.isVisible && this.currentFocusIndex === 0 && shiftKey;
            const tabForward = this.isVisible && this.currentFocusIndex === links.length - 1 && !shiftKey;
            const shouldPrevent = tabBackward || tabForward;

            if (shouldPrevent) {
                event.preventDefault();
            }
        },

        /**
         * Handle document click event. Used to close the app switcher when
         * clicking outside.
         *
         * @param {MouseEvent} event
         * @returns {void}
         */
        onDocumentClick(event) {
            const isOutsideClick = this.$refs.appSwitcher && !this.$refs.appSwitcher.$el.contains(event.target);

            if (this.isVisible && isOutsideClick) {
                this.close();
            }
        },

        /**
         * Handle keydown event.
         *
         * @param {KeyboardEvent}
         * @returns {void}
         */
        onKeyDown(event) {
            const { code } = event;

            switch (code) {
                case 'ArrowDown':
                case 'ArrowUp':
                    if (this.isVisible) event.preventDefault();
                    break;

                case 'Tab':
                    this.handleTabPress(event);
                    break;

                default:
                    return;
            }
        },

        /**
         * Handle keyup event.
         *
         * @param {KeyboardEvent}
         * @returns {void}
         */
        onKeyUp(event) {
            const { code } = event;

            switch (code) {
                case 'ArrowDown':
                    this.focusNext(event);
                    break;

                case 'ArrowUp':
                    this.focusPrevious(event);
                    break;

                case 'Escape':
                    this.isVisible && this.close();
                    break;

                default:
                    return;
            }
        },

        /**
         * Set app switcher visibility.
         */
        open() {
            this.isVisible = true;
            this.registerEvents();
        },

        /**
         * Toggle app switcher visiblity.
         *
         * @returns {void}
         */
        toggleVisibility() {
            this.isVisible ? this.close() : this.open();
        },

        /**
         * Determine whether the current application user should be able to see
         * the app switcher component at all.
         *
         * This depends on whether they have an active or trial version of
         * *both* Dext applications. If they don't, then they don't get to see
         * the app switcher.
         *
         * It also depends on whether both the current team and the current user
         * are marked as dext.
         *
         * @returns {Bool}
         */
        setUserAccess(updatedProducts = {}) {
            const validProductStatusList = ['active', 'expired', 'trial'];
            const { teamIsDext, teamProducts, userIsDext } = window.Xavier;
            const isValidDext = teamIsDext && userIsDext;

            const productList = Object.values(updatedProducts).length ? updatedProducts : teamProducts;
            const hasPrecision = productList.precision && validProductStatusList.includes(productList.precision);
            const hasPrepare = productList.prepare && validProductStatusList.includes(productList.prepare);

            /**
             * Given I am a dext user
             * and I am in a dext team
             * and my team has access to both precision and prepare.
             */
            this.userHasAccess = isValidDext && hasPrecision && hasPrepare;
            this.$emit('user-access-updated', this.userHasAccess);
        },

        clearEvents() {
            window.removeEventListener('click', this.onDocumentClick);
            window.removeEventListener('keyup', this.onKeyUp);
            window.removeEventListener('keydown', this.onKeyDown);
        },

        registerEvents() {
            this.clearEvents();
            window.addEventListener('click', this.onDocumentClick);
            window.addEventListener('keyup', this.onKeyUp);
            window.addEventListener('keydown', this.onKeyDown);
        },
    },

    beforeDestroy() {
        this.clearEvents();
    },

    created() {
        this.loadDextTeamMembers();
        window.Bus.$on('teamProductUpdate', (products) => {
            this.setUserAccess(products);
            this.registerEvents();
        });
    },

    mounted() {
        this.setUserAccess();
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.app-switcher-container {
    margin-left: 8px;
    position: relative;
}

.app-switcher-button {
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    color: get-color(gray, lite);
    height: 32px;
    line-height: 1;
    padding: 0;
    transition: color 0.15s ease;
    width: 32px;

    &:hover,
    &:focus,
    &-active {
        background-color: get-color(silver, medium);
        color: get-color(gray);
        outline: 0;
    }

    &:focus-visible {
        @include focus-outline($color: get-color(blue), $inset: true);
    }

    &:disabled {
        background-color: transparent;
        color: get-color(silver, medium);
    }
}

.app-switcher {
    background-color: $color-white;
    border: 1px solid get-color(silver);
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba($color-black, 0.1);
    left: 0;
    padding-bottom: 8px;
    padding-top: 8px;
    position: absolute;
    top: 32px;
    width: 216px;
    z-index: 11;
}

.app-switcher-title {
    color: get-color(gray, medium);
    font-size: pxtoem(16);
    font-weight: $font-weight-bold;
    letter-spacing: 0.01em;
    line-height: 1.2;
    margin-bottom: 0;
    padding: 7px 16px 6px;
}

.app-switcher-link {
    @include focus-outline($color: get-color(blue), $inset: true);

    color: get-color(charcoal, lite);
    display: block;
    font-size: pxtoem(13);
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: get-color(orange, lite);
    }
}

.app-switcher-link-content {
    align-items: center;
    border-left: 1px solid get-color(orange);
    display: flex;
    min-height: 32px;
    padding-left: 8px;
    padding-top: 1px;
}

.app-switcher-label {
    letter-spacing: 0.01em;
    line-height: 1.5;
}

.app-switcher-tagline {
    color: get-color(gray, medium);
    font-size: pxtoem(11);
    font-weight: $font-weight-regular;
}

.app-switcher-link-icon {
    margin-right: 5px;
    transition: color 0.2s ease-in-out;
}

.app-switcher-link-icon-prepare {
    color: get-color(red);
}

.app-switcher-link-icon-precision {
    color: get-color(blue);
}
</style>
