import type { RouteConfig } from 'vue-router';
import { extractClientIdParam } from '../utils';

const insightRoutes: RouteConfig[] = [
    {
        path: 'insight/historical-changes',
        component: () => import('@/scenes/HistoricalChanges/HistoricalChangesScene.vue'),
        props: extractClientIdParam,
        name: 'client.insight.historicalChanges',
    },
    {
        path: 'insight/lock-dates',
        component: () => import('@/scenes/LockDateHistory/LockDateHistoryScene.vue'),
        props: extractClientIdParam,
        name: 'client.insight.lockDates',
    },
    {
        path: 'insight/corporation-tax',
        component: () => import('@/scenes/CorporationTax/CorporationTaxScene.vue'),
        props: extractClientIdParam,
        name: 'client.insight.corporationTax',
    },
    {
        path: 'insight/fixed-assets',
        component: () => import('@/scenes/FixedAssets/FixedAssetsScene.vue'),
        props: extractClientIdParam,
        name: 'client.insight.fixedAssets',
    },
    {
        path: 'insight/aged-balances',
        component: () => import('@/scenes/AgedBalances/AgedBalancesScene.vue'),
        props: extractClientIdParam,
        name: 'client.insight.agedBalances',
    },
    {
        path: 'insight/control-accounts',
        component: () => import('@/scenes/ControlAccounts/ControlAccountsScene.vue'),
        props: extractClientIdParam,
        name: 'client.insight.controlAccounts',
    },
    {
        path: 'insight/cost-analysis',
        component: () => import('@/scenes/CostAnalysis/CostAnalysisScene.vue'),
        props: extractClientIdParam,
        name: 'client.insight.costAnalysis',
    },
    {
        path: 'insight/activity-stats',
        component: () => import('@/scenes/ActivityStats/ActivityStatsScene.vue'),
        props: extractClientIdParam,
        name: 'client.insight.activityStats',
    },
    {
        path: 'insight/sales-analysis',
        component: () => import('@/scenes/SalesAnalysis_LEGACY/SalesAnalysisScene.vue'),
        props: extractClientIdParam,
        name: 'client.insight.salesAnalysis',
    },
    {
        path: 'insight/payroll',
        component: () => import('@/scenes/Payroll'),
        props: extractClientIdParam,
        name: 'client.insight.payroll',
    },
];

export { insightRoutes };
