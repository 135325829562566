<template>
    <div class="disconnected-client text-left">
        <VButton
            link
            name="Go to Client"
            text
            :to="{ name: 'client.overview', params: { clientSlug: params.data.slug } }"
            v-if="subscriptionValid"
        >
            {{ params.data.name }}
        </VButton>

        <span class="text-primary" v-else>
            {{ params.data ? params.data.name : '' }}
        </span>

        <DBadge variant="error">Disconnected</DBadge>
    </div>
</template>

<script>
import Vue from 'vue';

import { DBadge } from '@/components/Badge';
import useXavierGlobals from '@/hooks/useXavierGlobals';

export default Vue.extend({
    name: 'DisconnectedClientRenderer',
    components: { DBadge },
    props: {
        params: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const { subscriptionValid } = useXavierGlobals();

        return { subscriptionValid };
    },
});
</script>

<style lang="scss">
.disconnected-client {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
}

.d-badge {
    margin-left: auto;
}
</style>
