import type { RouteConfig } from 'vue-router';

/* eslint-disable sort-keys */
const workflowsRoutes: RouteConfig[] = [
    {
        component: { template: '<router-view />' },
        meta: { disableLegacyStyles: true },
        path: 'workflows',

        children: [
            {
                component: () => import('@/scenes/Workflows/WorkflowsList').then((m) => m.WorkflowsListSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'team.workflows.list',
                path: '/',
            },
            {
                path: 'custom-checks',
                component: () => import('@/scenes/Workflows/CustomChecks').then((m) => m.CustomChecksSceneContainer),
                name: 'team.workflows.custom-checks.list',
                meta: { disableLegacyStyles: true },
            },
            {
                component: () =>
                    import('@/scenes/Workflows/DraftWorkflows').then((m) => m.DraftWorkflowsSceneContainer),
                meta: { disableLegacyStyles: true },
                name: 'team.workflows.drafts.list',
                path: 'drafts',
            },
            {
                component: () =>
                    import('@/scenes/Workflows/WorkflowTemplates').then((m) => m.WorkflowTemplatesSceneContainer),

                meta: { disableLegacyStyles: true },
                name: 'team.workflows.templates.list',
                path: 'templates',
            },
            {
                component: () =>
                    import('@/scenes/Workflows/WorkflowsRepeatingList').then(
                        (m) => m.WorkflowsRepeatingListSceneContainer
                    ),
                name: 'team.workflows.repeating.list',
                path: 'repeating',
            },
        ],
    },
];

export { workflowsRoutes };
