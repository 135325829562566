import * as env from '@/config/env';
import { DEV_loadWorker } from '@/utils/loadWorker';
import TimeoutWorker from '@/workers/timeout.worker?worker';

const timeoutWorker = env.IS_DEV ? DEV_loadWorker('timeout.worker.ts') : new TimeoutWorker();

// Intercept api requests to make sure we are making queries for the most current team
export function ensureCorrectTeam(store) {
    return function (config) {
        const pathPrefix = config.url.substring(0, 5);

        return store.getters['teams/wrongTeam'] && ['/api/', '/clie'].indexOf(pathPrefix) >= 0
            ? (window.location = '/refresh-team')
            : config;
    };
}

/**
 * Intercept api requests to reset session timeout

 * @param {Function} inactiveCallback
 * @param {Integer} timeout Milliseconds until session timeout
 * @returns Function
 */
export function watchForInactivity(inactiveCallback, timeout) {
    const timeoutListener = ({ data }) => {
        data.id === 'expired' && inactiveCallback();
    };

    timeoutWorker.addEventListener('message', timeoutListener);
    timeoutWorker.postMessage({ command: 'start', payload: { timeout } });

    return function (response) {
        if (response.status === 401) {
            return;
        }

        /**
         * These are called every few minutes so don't count as user activity.
         */
        if (response.config.url === '/spark/token') {
            return;
        }

        timeoutWorker.postMessage({ command: 'stop' });
        timeoutWorker.postMessage({ command: 'start', payload: { timeout } });

        return response;
    };
}

export function handleAuthErrors(errorCallback) {
    return function (error) {
        if (error.response === undefined) {
            return Promise.reject(error);
        }

        if ([401, 419].includes(error.response.status)) {
            errorCallback(error);
        }

        return Promise.reject(error);
    };
}
