<template>
    <DButton
        :append-icon="appendIcon"
        :data-qa="qaNameAttr"
        :disabled="disabled"
        :href="href"
        :icon="icon"
        :loading="loading"
        :new-tab="newTab"
        :prepend-icon="prependIcon"
        :text="text"
        :to="to"
        :type="type"
        :variant="variant"
        v-bind="$attrs"
        v-on="listeners"
        @click="onClick"
    >
        <slot />
    </DButton>
</template>

<script setup lang="ts">
import { computed, useListeners } from 'vue';

import { useAnalytics } from '@/providers/AnalyticsProvider';
import { omit } from '@/utils/object';
import { generateQaName } from '@/utils/strings';

import type { DButtonType, DButtonVariant, DIconTuple } from '../DextUi/DButton/types';
import type { DIconName } from '../DextUi/DIcon/DIcon.types';
import type { Location } from 'vue-router';

import { DButton } from '../DextUi/DButton';

const analytics = useAnalytics();
const $listeners = useListeners();

type Props = {
    appendIcon?: DIconTuple | DIconName;
    disabled?: boolean;
    href?: string;
    icon?: DIconTuple | DIconName;
    loading?: boolean;
    newTab?: boolean;
    prependIcon?: DIconTuple | DIconName;
    qaName: string;
    text?: boolean;
    to?: Location;
    type?: DButtonType;
    variant?: DButtonVariant;
};

const props = withDefaults(defineProps<Props>(), {
    appendIcon: undefined,
    disabled: undefined,
    href: undefined,
    icon: undefined,
    iconVariant: 'thick',
    loading: false,
    prependIcon: undefined,
    qaName: undefined,
    text: false,
    to: undefined,
    type: 'submit',
    variant: 'default',
});

const emit = defineEmits(['click']);

function onClick(event: MouseEvent) {
    if (props.disabled) return;

    if (props.text) {
        const url = props.to?.path ?? props.href ?? '';
        const isExternal = !!props.href;

        analytics?.trackLinkClick(qaNameAttr.value, url, isExternal);
    } else {
        analytics?.trackButtonClick(qaNameAttr.value);
    }

    emit('click', event);
}

const listeners = computed(() => {
    return omit($listeners, ['click']);
});

const qaNameAttr = computed(() => {
    return generateQaName(props.qaName);
});
</script>

<script lang="ts">
export default {
    name: 'PButton',
};
</script>
