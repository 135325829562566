<template>
    <div v-if="filteredClients" class="text-center mt-5 fs-unmask">
        <p>
            No clients found for your current search filters.<br />
            If you can't find the client you are looking for you can import a new client from
            {{ quickbooksEnabled ? 'Xero or QuickBooks' : 'Xero' }}.
        </p>
        <CreateClientButton class="create-client-button" secondary>{{
            quickbooksEnabled ? 'Add Client' : 'Add Client from Xero'
        }}</CreateClientButton>
    </div>

    <div v-else class="container text-center py-5 fs-unmask">
        <div v-if="newClientListEmptyState">
            <div class="card-wrapper row px-0 py-0 m-3">
                <ImportClientCard />
                <LearnAboutPrecisionCard />
            </div>
        </div>

        <div v-else class="fs-unmask">
            <div class="container-fluid text-center py-5">
                <img class="mb-5" src="/img/dext-import-client.svg" />

                <h2>No Clients Yet!</h2>
                <p>The first step in Dext Precision is to get a client up and running.</p>
                <p>
                    To do this, we'll redirect you to {{ quickbooksEnabled ? 'Xero or QuickBooks' : 'Xero' }}.
                    <br />From there, you'll be asked which client you'd like to grant access for Dext Precision.
                    <br />Once you're redirected back here, we'll start the process of importing the client's data.
                    <br />This process can take between 1 and 60 minutes, depending on the size of the client. <br />We
                    also send you an email once it's done.
                </p>
                <p class="mb-4">
                    For more information on how we sync with {{ quickbooksEnabled ? 'Xero and QuickBooks' : 'Xero' }},
                    check out our
                    <a
                        href="https://help.dext.com/en/category/precision/using-precision/team-home/integrations"
                        target="_blank"
                    >
                        support article </a
                    >.
                </p>

                <CreateClientButton class="create-client-button" secondary>Import your first client</CreateClientButton>
            </div>
        </div>
    </div>
</template>

<script>
import { Feature } from '@/enums/Feature';
import CreateClientButton from '@/components/ClientList/CreateClientButton.vue';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

import ImportClientCard from './components/ImportClientCard.vue';
import LearnAboutPrecisionCard from './components/LearnAboutPrecisionCard.vue';

export default {
    components: {
        ImportClientCard,
        LearnAboutPrecisionCard,
        CreateClientButton,
    },

    props: {
        filteredClients: {
            default: false,
            required: false,
            type: Boolean,
        },

        quickbooksEnabled: {
            default: false,
            required: false,
            type: Boolean,
        },

        methods: {
            toggleModalOn() {
                this.showModal = true;
            },
        },
    },

    setup() {
        return {
            newClientListEmptyState: useIsFeatureEnabled(Feature.NEW_CLIENT_LIST_EMPTY_STATE),
        };
    },
};
</script>

<style lang="scss" scoped>
.create-client-button {
    justify-content: center;
}

.card-wrapper {
    max-width: 900px;
}
</style>
