import { inject } from 'vue';

import type { TeamContextApi } from '../App.types';

export const TeamContext = Symbol('TeamContext');

export function useTeamContext(componentName: string) {
    const context = inject<TeamContextApi>(TeamContext);

    if (!context) {
        throw new Error(`<${componentName} /> is missing a parent <PrecisionAppContainer /> component.`);
    }

    return context;
}
