import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const workflowsRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/Workflows/WorkflowDetails').then((m) => m.WorkflowDetailsSceneContainer),
        meta: {
            disableLegacyStyles: true,
            sidebar: false,

            breadcrumbs: [
                { iconName: 'back', label: 'Back to all workflows', to: { name: 'team.workflows.list' } },
                { label: 'Workflow mode' },
            ],
        },
        name: 'client.workflows.details',
        path: 'workflows/:workflowSlug',
        props: extractClientIdParam,
    },
];

export { workflowsRoutes };
