export function truncate(input: string, length: number) {
    return input.substring(0, length).trim();
}

export function uppercaseFirst(input: string) {
    if (!input) {
        return '';
    }

    return input[0].toUpperCase() + input.slice(1);
}

export function extractInitials(input: string, uppercase = true) {
    if (!input) {
        return '';
    }

    return input
        .split(' ')
        .map((w) => (uppercase ? w[0].toUpperCase() : w[0]))
        .join('');
}

export function convertToSlug(input: string) {
    if (!input) {
        return '';
    }

    return input
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
}

export function stripSpecialCharacters(input: string) {
    if (!input) {
        return '';
    }

    return input.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
}

export function collapseSpaces(input: string) {
    if (!input) {
        return '';
    }

    return input.replace(/\s+/g, ' ');
}

export function generateQaName(input: string) {
    return input.toLowerCase().replace(/[^a-zA-Z0-9\.]/g, '.');
}
