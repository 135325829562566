<template>
    <div>
        <Dropdown>
            <template slot="toggler">
                <div class="toggle-profile-list-button-wrapper">
                    <VButton class="toggle-profile-list-button" icon>
                        <VIcon
                            decorative
                            :name="'profile-thick'"
                            :size="20"
                            :vertical-align="false"
                            v-tooltip="{ content: 'Profile', placement: 'left', distance: 16 }"
                        />
                    </VButton>
                </div>
            </template>
            <DropdownContent :pull-right="true">
                <div class="content-wrapper">
                    <DropdownItem>
                        <div class="profile-dropdown-item-wrapper" @click="goToPersonalDetails">
                            <div class="profile-dropdown-item-content">
                                <VIcon
                                    color="#C3CBD5"
                                    decorative
                                    :name="'user-settings-thick'"
                                    :size="20"
                                    :vertical-align="false"
                                />
                                <div class="profile-dropdown-item-text">
                                    <span class="profile-dropdown-item-text-header">Personal details</span>
                                    <span class="profile-dropdown-item-text-details">
                                        Check and update your email, name or mobile number.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </DropdownItem>

                    <DropdownItem>
                        <div class="profile-dropdown-item-wrapper" @click="goToSecurity">
                            <div class="profile-dropdown-item-content">
                                <VIcon
                                    color="#C3CBD5"
                                    decorative
                                    :name="'resilience-shield-security-thick'"
                                    :size="20"
                                    :vertical-align="false"
                                />
                                <div class="profile-dropdown-item-text">
                                    <span class="profile-dropdown-item-text-header">Security</span>
                                    <span class="profile-dropdown-item-text-details">
                                        Change your password or improve your account's security.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </DropdownItem>

                    <DropdownItem v-if="isManageXeroCredentialsEnabled">
                        <RouterLink :to="{ name: 'team.settings.manageTokens' }">
                            <div class="profile-dropdown-item-wrapper">
                                <div class="profile-dropdown-item-content">
                                    <VIcon
                                        color="#C3CBD5"
                                        decorative
                                        :name="'connections-thick'"
                                        :size="20"
                                        :vertical-align="false"
                                    />
                                    <div class="profile-dropdown-item-text">
                                        <span class="profile-dropdown-item-text-header">Manage OAuth Tokens</span>
                                        <span class="profile-dropdown-item-text-details">
                                            Manage Xero OAuth tokens.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </RouterLink>
                    </DropdownItem>

                    <DropdownItem>
                        <hr class="separator" />
                    </DropdownItem>

                    <DropdownItem>
                        <div class="profile-dropdown-item-wrapper" @click="signOut">
                            <div class="profile-dropdown-item-content">
                                <VIcon
                                    color="#C3CBD5"
                                    decorative
                                    :name="'log-out-filled'"
                                    :size="20"
                                    :vertical-align="false"
                                />
                                <div class="profile-dropdown-item-text">
                                    <span class="profile-dropdown-item-text-header">Sign out</span>
                                </div>
                            </div>
                        </div>
                    </DropdownItem>
                </div>
            </DropdownContent>
        </Dropdown>
    </div>
</template>

<script>
import { Dropdown, DropdownItem, DropdownContent } from '@/components/Dext/common/Dropdown';
import { mapActions } from 'vuex';
import { Feature } from '@/enums/Feature';
import useIsFeatureEnabled from '@/store/modules/feature/hooks/useIsFeatureEnabled';

export default {
    name: 'ProfileDropdown',
    components: {
        Dropdown,
        DropdownItem,
        DropdownContent,
    },
    computed: {
        isManageXeroCredentialsEnabled() {
            return useIsFeatureEnabled(Feature.MANAGE_XERO_CREDENTIALS);
        },
    },
    methods: {
        ...mapActions({ resetQboClientsClicked: 'session/resetQboClientsClicked' }),

        goToPersonalDetails() {
            const { userIsDext, dextPersonalSettings } = window.Xavier;

            if (userIsDext) {
                window.location.href = dextPersonalSettings;

                return;
            }

            window.location.href = '/settings#/profile';
        },
        goToSecurity() {
            const { userIsDext, dextSecurity } = window.Xavier;

            if (userIsDext) {
                window.location.href = dextSecurity;

                return;
            }

            window.location.href = '/settings#/security';
        },
        signOut() {
            window.location.href = '/logout';
            this.resetQboClientsClicked();
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'style/dext/includes';

.toggle-profile-list-button-wrapper {
    align-items: center;
    border-radius: pxtoem(24);
    display: flex;
    height: pxtoem(32);
    justify-content: center;
    width: pxtoem(32);

    .toggle-profile-list-button {
        transition: none;
    }
}

.content-wrapper {
    margin-bottom: 8px;
    margin-top: 8px;
}

.separator {
    border-top: pxtoem(0.5) solid get-color(silver, medium);
    margin: pxtoem(6.5) pxtoem(16);
}

.profile-dropdown-item-wrapper {
    cursor: pointer;

    &:hover {
        background: get-color(orange, lite);
    }

    .profile-dropdown-item-content {
        display: flex;
        flex-direction: row;
        padding: pxtoem(6) pxtoem(16);

        .profile-dropdown-item-text {
            color: get-color(charcoal, lite);
            display: flex;
            flex-direction: column;
            font-size: pxtoem(13);
            line-height: pxtoem(20);
            margin-left: pxtoem(8);

            .profile-dropdown-item-text-header {
                font-weight: $font-weight-bold;
            }
        }
    }
}
</style>
