import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const checkRoutes: RouteConfig[] = [
    {
        path: 'check/vat-return',
        component: () => import('@/scenes/VATReturn'),
        props: extractClientIdParam,
        name: 'client.check.vatReturn',
    },
    {
        path: 'check/health-check',
        component: () => import('@/scenes/HealthCheck'),
        props: extractClientIdParam,
        name: 'client.check.healthCheck',
    },
    {
        path: 'check/entertainment-accounts',
        component: () => import('@/scenes/EntertainmentAccountsCheck').then((res) => res.EntertainmentAccountsCheck),
        props: extractClientIdParam,
        name: 'client.check.entertainmentAccounts',
    },
];

export { checkRoutes };
