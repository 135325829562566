import { PrecisionAppContainer } from '@/App';
import TeamLayout, { TeamSidebar } from '@/layout/TeamLayout';

import type { RouteConfig } from 'vue-router';

import { adminRoutes } from './admin';
import { clientRoutes } from './client';
import { configureImportRoutes } from './configure-import';
import { teamRoutes } from './team';
import { userRoutes } from './user';

export const routes: RouteConfig[] = [
    {
        component: PrecisionAppContainer,
        name: 'app.home',
        path: '',
        props: true,

        children: [
            {
                component: TeamLayout,
                meta: { sidebar: TeamSidebar },
                path: '/teams/:teamRbExternalId',

                children: [
                    ...configureImportRoutes,
                    ...teamRoutes,
                    ...clientRoutes,
                    ...userRoutes,
                    {
                        component: () => import('@/scenes/PlatformClientSetup/PlatformClientSetupScene.vue'),
                        name: 'dext.platform.client.setup',
                        path: 'client-setup',
                    },
                ],
            },
        ],
    },

    ...adminRoutes,

    // This is purely a development route, and will by default render
    // a 404 page.
    {
        component: () => import('@/scenes/Errors/DevelopmentTesting.vue'),
        name: 'error.developmentTesting',
        path: '/errors/:code',
        props: true,
    },
    {
        component: () => import('@/scenes/Login/RedirectSceneContainer.vue'),
        name: 'login',
        path: '/login',
        props: false,
    },
    {
        component: () => import('@/scenes/Errors/404NotFound.vue'),
        name: 'error.404.notFound',
        path: '*',
    },
];
