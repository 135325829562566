import type { RouteConfig } from 'vue-router';

const practiceDashboardsRoutes: RouteConfig[] = [
    {
        component: { template: '<router-view />' },
        meta: { disableLegacyStyles: true },
        path: 'practice-dashboards',

        children: [
            {
                component: () =>
                    import('@/scenes/PracticeDashboards/CustomDashboards').then(
                        (m) => m.CustomDashboardsSceneContainer
                    ),
                meta: { disableLegacyStyles: true },
                name: 'team.practice-dashboards.custom-dashboards.list',
                path: '/',
            },
        ],
    },
];

export { practiceDashboardsRoutes };
