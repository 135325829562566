<template>
    <SidebarGroup name="Tax">
        <template #activator>Historical Activity</template>

        <template #icon-left>
            <VIcon decorative name="history-thick" />
        </template>

        <SidebarItem v-for="item in items" :key="item.name" :name="item.name" sub-item :to="item.to">
            {{ item.label }}
        </SidebarItem>
    </SidebarGroup>
</template>

<script>
import PropTypes from 'vue-types';

import { SidebarGroup, SidebarItem } from '@/layout/Sidebar';

import useBaseGroup from '../hooks/useBaseGroup';
import ClientProvider from '@/enums/ClientProvider';

export default {
    name: 'ClientSidebarGroupHistoricalActivity',

    components: { SidebarGroup, SidebarItem },

    props: {
        client: PropTypes.object.isRequired,
        reportMode: PropTypes.string.isRequired,
    },

    setup(props) {
        const { createItems, isInsightSupported } = useBaseGroup(props.client, props.reportMode);

        return {
            createItems,
            isInsightSupported,
        };
    },

    computed: {
        items() {
            return this.createItems([
                this.isInsightSupported('lock-dates') && {
                    name: 'Lock Date History',
                    label: 'Lock Date History',
                    to: { name: 'client.insight.lockDates' },
                },
                this.client.provider === ClientProvider.XERO &&
                    this.isInsightSupported('historical-changes') && {
                        name: 'Historical Changes',
                        label: 'Historical Changes',
                        to: { name: 'client.insight.historicalChanges' },
                    },
            ]);
        },
    },
};
</script>
