<template>
    <div class="client-link">
        <VButton
            link
            name="Go to Client"
            text
            :to="{ name: 'client.overview', params: { clientSlug: params.data.slug } }"
            v-if="subscriptionValid && params.data"
        >
            {{ params.data.name }}
        </VButton>

        <span class="text-primary" v-else>
            {{ params.data ? params.data.name : '' }}
        </span>
    </div>
</template>

<script>
import Vue from 'vue';
import useXavierGlobals from '@/hooks/useXavierGlobals';

export default Vue.extend({
    name: 'ClientLinkRenderer',
    props: {
        params: {
            type: Object,
            required: true,
        },
    },

    setup() {
        const { subscriptionValid } = useXavierGlobals();

        return { subscriptionValid };
    },
});
</script>

<style lang="scss" scoped>
.client-link {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
}
</style>
