<template>
    <PrecisionApp :has-errors="appStore.hasErrors" :loading="appStore.isLoading" :valid-team="isValidTeam" />
</template>

<script setup lang="ts">
import pluck from 'lodash-es/map';
import { computed, nextTick, onBeforeMount, onMounted, provide, ref, watch } from 'vue';
import { useGetters } from 'vuex-composition-helpers';

import { useRoute } from '@/composables/useRoute';
import { useRouter } from '@/composables/useRouter';
import { useToaster } from '@/composables/useToaster';
import { useAppStore, useToastsStore } from '@/store/app';
import { useTeamsStore } from '@/store/teams';

import type { AppContextApi, TeamContextApi, UserContextApi, ClientContextApi } from './App.types';
import type { TeamModel } from '@/schemas/Team.schema';

import { AppContext } from './composables/useAppContext';
import { ClientContext } from './composables/useClientContext';
import { TeamContext } from './composables/useTeamContext';
import { UserContext } from './composables/useUserContext';
import PrecisionApp from './PrecisionApp.vue';

type Props = {
    teamRbExternalId?: string;
};

const props = defineProps<Props>();

/* Initialisation stores
 *
 * These stores are required for the application to run. If they are not booted
 * Then we should not render the application.
========================================================================== */
const appStore = useAppStore();
const teamsStore = useTeamsStore();
const toastsStore = useToastsStore();
const { currentUser } = useGetters('users', ['currentUser']);
const { currentClient } = useGetters('legacyClients', ['currentClient']);

const route = useRoute();
const router = useRouter();
const toaster = useToaster();

const isPrecisionEmbedded = ref(false);

const isValidTeam = computed(() => {
    if (teamsStore.currentTeam) {
        return true;
    }

    const teamIdsAsString = pluck(teamsStore.byId, 'rbExternalId').map((value) => value?.toString());

    return Boolean(props.teamRbExternalId && teamIdsAsString.includes(props.teamRbExternalId));
});

async function redirectToTeamHome() {
    // A whole bunch of state is being updated, we need to wait for it to all be
    // ready before we can redirect the user to the correct page.
    await nextTick();

    /**
     * @TODO: TEAM_CRN_IN_URL: Remove the cast when we don't need the
     *        legacy team store anymore.
     */
    const { rbExternalId } = teamsStore.currentTeam as TeamModel;

    router?.replace({
        name: 'team.home',
        params: { teamRbExternalId: rbExternalId.toString() },
    });
}

provide<TeamContextApi>(TeamContext, {
    /**
     * @TODO: TEAM_CRN_IN_URL: Remove the cast when we don't need the
     *        legacy team store anymore.
     */
    currentTeam: computed(() => teamsStore.currentTeam as TeamModel),
});

provide<UserContextApi>(UserContext, {
    currentUser,
});

provide<ClientContextApi>(ClientContext, {
    currentClient,
});

provide<AppContextApi>(AppContext, {
    isEmbedded: isPrecisionEmbedded,
});

toastsStore.$subscribe((_mutation, state) => {
    const nextToast = state.toasts[0];

    if (!toaster || !nextToast) return;

    toastsStore.removeToast(nextToast.id);
    toaster[nextToast.type](nextToast.message, {
        timeout: nextToast.timeout ?? 8000,
    });
});

watch(
    () => appStore.isInitialised,
    (newValue) => {
        if (newValue && !props.teamRbExternalId) {
            redirectToTeamHome();
        }
    },
    { immediate: true }
);

watch(
    () => route.value?.name,
    (newValue) => {
        if (newValue === 'app.home') {
            redirectToTeamHome();
        }
    }
);

onBeforeMount(() => {
    appStore.init();
});

onMounted(() => {
    isPrecisionEmbedded.value = window.self !== window.top;
});
</script>
