import type { RouteConfig } from 'vue-router';

import { extractClientIdParam } from '../utils';

const insightsRoutes: RouteConfig[] = [
    {
        component: () => import('@/scenes/Insights/SalesAnalysis/SalesAnalysisSceneContainer.vue'),
        meta: { breadcrumbs: [], disableLegacyStyles: true },
        name: 'client.insights.salesAnalysis',
        path: 'insights/sales-analysis',
        props: extractClientIdParam,
    },
];

export { insightsRoutes };
